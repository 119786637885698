/* Testimonial form */

import { Box, Button, ButtonGroup, Flex, useToast } from "@chakra-ui/react"
import { yupResolver } from "@hookform/resolvers/yup"
import React from "react"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import {
  PhoneMaskInput,
  RadioInput,
  StarRatingInput,
  TextareaInput,
  TextInput,
} from "../custom-inputs"

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

const schema = yup.object({
  rating: yup
    .number()
    .required("Please select a rating")
    .min(1, "Please select a rating")
    .max(5, "Please select a rating"),
  name: yup.string().trim().required("Please enter your name"),
  email: yup
    .string()
    .trim()
    .email(
      "Please enter a valid email address in the following format: email@example.com"
    )
    .required("Please enter your email"),
  phone: yup
    .string()
    .required("Please enter your phone number")
    .matches(phoneRegex, "Please enter a valid 10-digit US phone number"),
  review: yup.string().trim().required("Please enter your review"),
  consent: yup.string().oneOf(["yes", "no"]),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const TestimonialForm = ({ onClose }) => {
  const toast = useToast()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rating: 0,
      name: "",
      email: "",
      phone: "",
      review: "",
      consent: "yes",
    },
  })

  const onSubmit = (data, e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "testimonial",
        ...data,
      }),
    })
      .then(() => {
        onClose()
        toast({
          title: "Thank you!",
          description:
            "We appreciate you taking the time to write us a review. We look forward to serving you again in the future!",
          status: "success",
          duration: 7000,
          isClosable: true,
          position: "top",
          variant: "subtle",
        })
      })
      .catch(error => console.log(error))

    e.target.reset()
    reset({ rating: 0, phone: "", consent: "yes" })
  }

  return (
    <Flex
      direction="column"
      as="form"
      w="100%"
      onSubmit={handleSubmit(onSubmit)}
      name="testimonial"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      p={4}
    >
      <input type="hidden" name="form-name" value="testimonial" />
      <StarRatingInput
        isInvalid={errors.rating}
        control={control}
        errorMessage={errors.rating && errors.rating.message}
      />
      <TextInput
        name="name"
        type="text"
        isInvalid={errors.name}
        label="Name"
        control={control}
        errorMessage={errors.name && errors.name.message}
      />
      <TextInput
        name="email"
        type="email"
        isInvalid={errors.email}
        label="Email address"
        control={control}
        errorMessage={errors.email && errors.email.message}
      />
      <PhoneMaskInput
        isInvalid={errors.phone}
        control={control}
        errorMessage={errors.phone && errors.phone.message}
      />
      <TextareaInput
        name="review"
        type="text"
        isInvalid={errors.review}
        label="Your review"
        control={control}
        errorMessage={errors.review && errors.review.message}
      />
      <RadioInput
        isInvalid={errors.consent}
        control={control}
        errorMessage={errors.consent && errors.consent.message}
      />
      <Box textAlign="center" mt={8}>
        <ButtonGroup spacing="6">
          <Button
            colorScheme="yellow"
            onClick={onClose}
            type="button"
            minW="100px"
          >
            Cancel
          </Button>
          <Button
            colorScheme="yellow"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
            type="submit"
            minW="100px"
          >
            Submit
          </Button>
        </ButtonGroup>
      </Box>
    </Flex>
  )
}

export default TestimonialForm
