/* Testimonial form */

import { Button, Collapse, Flex, Stack, useDisclosure } from "@chakra-ui/react"
import React from "react"
import TestimonialForm from "./form"

const TestimonialFormButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Flex justify="center" my={[4, 8]}>
      <Stack maxW="500px" w="100%">
        <Button onClick={onOpen} isDisabled={isOpen} colorScheme="yellow">
          Leave A Review
        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Flex>
            <TestimonialForm onClose={onClose} />
          </Flex>
        </Collapse>
      </Stack>
    </Flex>
  )
}

export default TestimonialFormButton
