/* Testimonials list page template */

import {
  Center,
  Container,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import React from "react"
import { FaSadTear } from "react-icons/fa"
import Pagination from "../components/pagination"
import Seo from "../components/seo"
import TestimonialFormButton from "../components/testimonial"
import TestimonialCard from "../components/testimonial-card"

const TestimonialsPageTemplate = ({ data, pageContext }) => {
  const testimonials = data.hasura.testimonial

  const { currentPage, numTotalPages } = pageContext
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === numTotalPages
  const prevPage =
    currentPage - 1 === 1
      ? "/testimonials"
      : `/testimonials/page-${
          currentPage - 1 !== 0 ? (currentPage - 1).toString() : "1"
        }`
  const nextPage = `/testimonials/page-${(currentPage + 1).toString()}`

  return (
    <>
      <Seo title="Testimonials" />
      <Flex
        direction="column"
        justify="center"
        align="center"
        py={[4, 8]}
        bg="gray.700"
      >
        <Heading as="h1" size="xl" color="teal.300" mb={2} textAlign="center">
          Testimonials
        </Heading>
        <Text color="gray.50" textAlign="center">
          What our customers are saying
        </Text>
      </Flex>
      <Container
        maxW="container.xl"
        py={[4, 8]}
        minH={["30vh", "40vh", "50vh"]}
      >
        <TestimonialFormButton />
        <Center>
          {testimonials.length > 0 ? (
            <SimpleGrid
              minChildWidth={["250px", "300px"]}
              spacing={[4, 4, 4, 8]}
              my={[4, 8]}
            >
              {testimonials.length &&
                testimonials.map((testimonial, index) => (
                  <TestimonialCard
                    key={index}
                    rating={testimonial.rating}
                    comment={testimonial.comment}
                    name={testimonial.name}
                    city={testimonial.city}
                    state={testimonial.state}
                    date={testimonial.date}
                  />
                ))}
            </SimpleGrid>
          ) : (
            <Center>
              <Flex my={[4, 8]}>
                <Text>No testimonials found</Text>
                <Icon as={FaSadTear} w={6} h={6} ml={2} color="blue.400" />
              </Flex>
            </Center>
          )}
          {numTotalPages > 1 && (
            <Pagination
              nextPage={nextPage}
              prevPage={prevPage}
              numTotalPages={numTotalPages}
              currentPage={currentPage}
              isFirstPage={isFirstPage}
              isLastPage={isLastPage}
              to="testimonials"
            />
          )}
        </Center>
      </Container>
    </>
  )
}

export const query = graphql`
  query Testimonials($limit: Int, $offset: Int, $date: HASURA_order_by = desc) {
    hasura {
      testimonial(order_by: { date: $date }, limit: $limit, offset: $offset) {
        id
        name
        comment
        city
        state
        rating
        date
      }
    }
  }
`

export default TestimonialsPageTemplate
